import firebase from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyAiRBlTNBA-ySjdo_qy0CJiN-8Pn3mdfYg',
  authDomain: 'epic-story-app.firebaseapp.com',
  databaseURL: 'https://epic-story-app-default-rtdb.firebaseio.com',
  projectId: 'epic-story-app',
  storageBucket: 'epic-story-app.appspot.com',
  messagingSenderId: '161122952681',
  appId: '1:161122952681:web:27b4be86d6e37c20dd5778'
};

export function getAuth(): firebase.auth.Auth {
  try {
    return firebase.app().auth();
  } catch (_e) {
    return firebase.initializeApp(firebaseConfig).auth();
  }
}

export function getGithubAuthProvider(): firebase.auth.GithubAuthProvider | null {
  try {
    const provider = new firebase.auth.GithubAuthProvider();
    provider.addScope('repo');

    return provider;

  } catch (error) {
    console.error('Cannot create GithubAuthProvider', error);
    return null;
  }
}

export function authenticate(provider: firebase.auth.AuthProvider): Promise<firebase.auth.UserCredential> {
  const auth = getAuth();

  try {
    return auth.signInWithPopup(provider);
  } catch (error) {
    return Promise.reject(error);
  }
}

export default firebase;
