import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

// These samples are intended for Web so this import would normally be
// done in HTML however using modules here is more convenient for
// ensuring sample correctness offline.
import { authenticate, getGithubAuthProvider } from './firebase';

const THEME = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#81ecec',
    },
    secondary: {
      main: '#fab1a0',
    },
  },
});

const ThemedApp: React.FC = () => {
  useEffect(() => {

    const githubAuthProvider = getGithubAuthProvider();

    if (!githubAuthProvider) {
      console.error('No GithubAuthProvider');
      return;
    }

    authenticate(githubAuthProvider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        const credential = result.credential;

        // This gives you a GitHub Access Token. You can use it to access the GitHub API.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const token = credential.accessToken;

        // The signed-in user info.
        const user = result.user;

        console.log('AUTHENTICATION SUCCESS', { user, token, credential })

      })
      .catch((error) => console.error('AUTHENTICATION ERROR', error));
  }, []);

  return (
    <ThemeProvider theme={THEME}>
      <App />
    </ThemeProvider>
  );
};

ReactDOM.render(<ThemedApp />, document.getElementById('root'));

serviceWorker.register();
